import React, { useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

const Participate = () => {
  const {
    strapiParticipatePage: { typeform_fr: typeformUrl },
  } = useStaticQuery(graphql`
    query particatePageFRQuery {
      strapiParticipatePage {
        typeform_fr
      }
    }
  `);

  useEffect(() => {
    if (typeof window !== 'undefined' && typeformUrl) {
      window.location.href = typeformUrl;
    }
  });

  return null;
};

export default Participate;
